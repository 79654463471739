.main {
    @apply flex flex-col;
    @apply min-h-screen;
    @apply bg-background-main;

    &.isLoading {
        @apply flex justify-center items-center;
        @apply relative;
        &::before {
            @apply flex justify-center items-center;
            @apply w-screen h-screen;
            @apply fixed top-0 left-0 z-30;
            @apply bg-primary-accent;
            @apply text-2xl;
            content: 'LOADING...';
            background: #F6D5CB url("/assets/images/logo.svg") no-repeat 50% 40%;
        }
    }

    .mainContent {
        @apply flex-col grow;
        @apply w-full;
        @apply relative;

        &.bgWhite {
            @apply bg-background-alt;
        }
    }
}