body:has(.hiddenScroll) {
  @apply overflow-y-hidden;
}

.main {
  @apply flex items-center;

  .row {
    @apply gap-2;

    .searchContainer {
      @apply flex flex-row;
      @apply w-full h-20 z-10;
      @apply translate-x-full;
      @apply duration-300;
      @apply fixed top-0 -right-full shadow-md;
      &.searchOpened {
        @apply p-6 bg-background-main;
        @apply translate-x-0 right-0;

        .formInput {
          @apply flex items-center;
          @apply w-full;

          form {
            @apply flex w-full;

            .searchInput {
              @apply w-full h-8 rounded-r-md outline-none;
              @apply bg-background-main;
              @apply text-[#4E4E4E] font-normal;
            }
          }
        }
      }
    }

    .menuIcon,
    button,
    .closeIcon {
      @apply flex items-center justify-center;
      @apply w-fit h-8;
      @apply p-2;
      @apply text-text;
      @apply bg-background-main;
      @apply rounded;
    }
    .navContainer {
      @apply flex flex-col justify-start items-center;
      @apply w-full h-full;
      @apply pb-2;
      @apply bg-background-main;
      box-shadow: -12px 3px 20px rgba(200, 200, 222, 0.2);
      border-left: 1px solid #fafaff;
      @apply translate-x-full;
      @apply duration-300;
      @apply fixed top-0 -right-full z-30;

      &.navOpened {
        @apply p-6 overflow-y-auto h-screen;
        @apply translate-x-0 right-0;

        .headerNav {
          @apply flex flex-row justify-between items-center;
          @apply w-full;
          @apply mb-8;

          .welcomeText {
            @apply flex flex-row items-center;

            .iconRounded {
              @apply flex flex-row justify-center items-center;
              @apply w-8 h-8;
              @apply mr-3 rounded-full;
              @apply bg-secondary-accent;

              svg {
                @apply w-6 h-6;
                @apply text-secondary;
                @apply scale-125;
              }
            }

            p {
              @apply text-secondary text-2xl font-bold;
            }
          }

          .closeIcon {
            @apply flex flex-row justify-center items-center;
            @apply w-3.5 h-3.5;
            @apply m-0 p-0;

            svg {
              @apply w-6 h-6;
              @apply scale-100;
            }
          }
        }

        .loginSection {
          @apply w-full mt-14 space-y-2;

          .logoutButton {
            @apply bg-[#FFEDED];
            @apply w-full h-16;
            @apply text-xl text-[#AE1010];
            @apply font-bold;
            @apply border border-[#AE1010];

            svg {
              @apply mr-[19px];
            }
          }

          .googleButtonLogin {
            @apply w-full h-16;
            @apply bg-white rounded-full space-x-4;
            @apply border border-[#e0e0e9];
            @apply font-bold text-[#1d1c2b];
          }

          .facebookButtonLogin {
            @apply w-full h-16;
            @apply bg-[#1877f2] rounded-full space-x-4;
            @apply font-bold text-white;
          }
        }
      }

      .buttonsHeader {
        @apply hidden;
      }
    }
  }
}

@screen lg {
  .main {
    .row {
      @apply gap-0;

      .searchContainer {
        @apply hidden;
      }
      .menuIcon,
      button,
      .closeIcon {
        @apply hidden;
      }

      .navContainer {
        @apply flex flex-row;
        @apply py-0;
        @apply translate-x-0;
        position: relative;
        background: transparent;
        box-shadow: unset;
        border-left: unset;
        width: unset;
        height: unset;
        top: unset;
        right: unset;
        z-index: unset;

        .headerNav {
          @apply hidden;
        }

        .buttonsHeader {
          @apply flex;
          @apply gap-x-2 ml-6;

          .searchForm {
            @apply w-[110px];

            form {
              @apply flex;

              .searchInput {
                @apply w-[70px] h-8 rounded-r-md pr-1 outline-none;
                @apply bg-background-main;
              }
            }
          }

          button {
            @apply flex;
            @apply pl-1 pr-1 py-1;
            @apply font-bold;

            &.searchIcon {
              @apply font-normal pr-2;

              svg {
                @apply ml-0 mr-2;
              }
            }
          }

          .menuIcon,
          .logoutButton {
            @apply bg-[#FFEDED];
            @apply text-base text-[#E04235];
            @apply font-bold;
            @apply border border-[#E04235];
            @apply h-[36px];

            svg {
              @apply ml-[7px];
            }
          }
        }

        .loginSection {
          @apply hidden;
        }
      }
    }
  }

  .loginSection {
    @apply flex flex-col;
    @apply border border-neutral-100 rounded-lg;

    .profileButton {
      @apply w-full;
      @apply flex gap-4;
      @apply px-6 py-2;
      @apply bg-white rounded-t-lg;
      @apply text-base text-[#1d1c2b];

      svg {
        @apply text-primary;
      }
    }

    .logoutButtonDropDown {
      @apply flex gap-4;
      @apply px-6 py-2 w-full;
      @apply bg-[#FDE8E8] rounded-b-lg;
      @apply text-base text-[#E02424];
    }

    .googleButtonLogin {
      @apply w-full;
      @apply flex gap-4;
      @apply px-6 py-2;
      @apply bg-white rounded-t-lg;
      @apply text-base text-[#1d1c2b];
    }

    .facebookButtonLogin {
      @apply flex gap-4;
      @apply px-6 py-2;
      @apply bg-[#1877f2] rounded-b-lg;
      @apply text-base text-white;
    }
  }
}
