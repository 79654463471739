.main {
    @apply flex justify-center items-center;
    @apply px-4 pt-4 mb-8;
    @apply relative;

    .row {
        @apply flex-row items-center px-4;
        @apply bg-white;
        @apply px-6 py-4;
        border-radius: 14px;

        .logo {
            @apply flex;
            @apply w-28;
        }

        .nav {
            @apply grow;
            @apply justify-end;
        }
    }
}

@screen lg {
    .main {
        @apply px-0 pt-4 mb-10;

        .row {
            @apply flex-row items-center;
            @apply px-8 py-4;

            .logo {
                @apply w-36;
            }
        }
    }
}