.footer {
    @apply flex flex-col justify-center items-center;
    @apply bg-secondary;
    @apply divide-y divide-white/25;

    .row {
        @apply flex-col flex-wrap items-center;
        @apply py-8 w-full;

        .col {
            @apply flex-col items-center text-center;

            p {
                @apply mt-4;
                @apply text-text-base text-white;
            }

            strong {
                @apply mt-6 mb-2;
                @apply text-text-lg font-bold text-white;
            }

            &:nth-child(2) {
                p {
                    @apply m-0;
                }
            }
        }

        &:last-child {
            @apply py-6 gap-2;

            p {
                @apply text-text-sm text-white;
            }
        }
    }

    .rowIcons {
        @apply w-max gap-6;

        div {
            @apply flex justify-center items-center;
            @apply w-12 h-12 rounded-lg bg-neutral-400/40 text-white;
        }
    }

    &.checkoutPage {
        @apply pb-0 #{!important};
    }
    
    &.campaignPage {
        @apply pb-48;
    }
}

@screen lg {
    .footer {
        .row {
            @apply flex-row items-start gap-x-8;

            .col {
                @apply flex-col items-start;
                @apply min-w-fit max-w-fit;
                @apply text-left;

                p {
                    @apply mt-7;
                }

                strong {
                    @apply mt-0;
                }

                &:first-child {
                    @apply grow;
                    @apply max-w-none;
                }
            }

            &:last-child {
                @apply justify-between;
            }
        }

        .rowIcons {
            @apply gap-2;
        }

        &.campaignPage {
            @apply pb-0;
        }
    }
}