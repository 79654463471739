.main {
  @apply flex flex-col items-center;
  @apply w-full;
  @apply gap-y-2;

  .divider {
    @apply w-[105px];
    @apply border-b border-border;
    @apply my-4;
  }

  li {
    @apply flex items-center;
    @apply w-full;
    @apply relative;

    &.itemHidden {
      @apply hidden;
    }

    &.active {
      a {
        @apply border-2 border-primary;
        @apply font-bold text-text;
        @apply relative;
      }
    }

    a {
      @apply flex items-center;
      @apply w-full;
      @apply border border-border;
      @apply font-normal no-underline;
      @apply text-text bg-background-alt;
      @apply rounded-lg;
      font-size: 1rem;
      line-height: 1.75rem;
      @apply px-6 py-4;

      span {
        @apply flex grow;

        svg {
          @apply text-primary;
          @apply mr-4;
        }
      }

      &:hover {
        @apply no-underline;
        //color: #AB3165;
      }
    }
  }
}

@screen lg {
  .main {
    @apply flex-row items-center;
    @apply gap-x-6 gap-y-0;

    .divider {
      @apply hidden;
    }

    li {
      @apply w-auto;
      
      &.hiddenLi{
        @apply hidden;
      }

      &.itemHidden {
        @apply flex;
      }

      &.active {
        &::before {
          @apply flex;
        }

        a {
          @apply text-primary;
          @apply border-0;
          @apply pl-2;

          &::after {
            @apply hidden;
          }
        }
      }

      a {
        @apply border-0;
        @apply py-0 px-0;

        svg {
          @apply hidden;
        }
      }
    }
  }
}