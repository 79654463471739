@import 'fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.banner {
  height: 360px;
}

.banner__link::after {
  /* animated border */
  clip-path: inset(0% round 0.1875em);
}

@media (min-width: 64rem) {
  .banner__figure {
    clip-path: polygon(100px 0%, 100% 0%, 100% 100%, 50px 100%);
    background-size: auto 105%;
    background-position: 88% 10%;
  }

  .banner--invert .banner__figure {
    clip-path: polygon(
      0% 0%,
      calc(100% - 100px) 0%,
      calc(100% - 50px) 100%,
      0% 100%
    );
  }

  .banner:hover .banner__figure {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}
